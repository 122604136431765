// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "output", "still", "loader" ]

  greet() {
    var input = this.inputTarget
    var output = this.outputTarget
    var still = this.stillTarget
    var loader = this.loaderTarget
    
    loader.classList.toggle("hidden");

    var href = new URL(still.src)
    href.searchParams.set('time', `${input.value}s`)
    console.log(href.toString()) // https://google.com/?q=dogs

    still.src = href.toString()

    output.textContent = `${input.value}`
  }

  loaded() {
    var still = this.stillTarget
    var loader = this.loaderTarget
    console.log("image loaded")
    loader.classList.toggle("hidden");
  }
}
