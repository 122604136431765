// https://github.com/gorails-screencasts/sortable-drag-and-drop

import { Controller } from "@hotwired/stimulus";
import { FetchRequest, patch } from "@rails/request.js";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      animation: 150,
      handle: ".handle",
      onEnd: this.end.bind(this),
    });
  }

  async end(event) {
    console.log(event);
    let id = event.item.dataset.id;
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    const url = this.data.get("url").replace(":id", id);
    const request = new FetchRequest("patch", url, { body: data });
    const response = await request.perform();
    if (response.ok) {
      // do nothing
    }
  }
}
